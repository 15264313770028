import React, {Component} from 'react';
import './Tips.scss';
import {ReactSVG} from "react-svg";
import close from "../../assets/images/saas/close.svg"
import I18N ,{i18n} from "../Util/I18nUtils";

let _that;
let _interval = null;
export default class Tips extends Component {
    constructor(props) {
        super(props);
        _that = this;
    }

    state = {
        isOpen: true,
        tipObjects: []
    };

    options = {
        title: 'tips...',
        // type值为warning/error/info/success
        type: 'success',
    };

    render() {
        return (
            <div hidden={!this.state.isOpen} className="tips">
                {
                    this.state.tipObjects.map((item, index) => {
                        return (
                            <div key={index}
                                 id={item['@index']}
                                 className="tip"
                                 onAnimationEnd={(e) => this.fnAnimationEnd(e, item)}
                            >
                                <div className="icon_head">
                                    <div className={`tips_icon icon_${item['type'] || 'success'}`}/>
                                    <div className="tips_type">
                                        <span>{item['tipInfo'] || i18n(I18N.SAAS_SYS_TIPS)}</span>
                                    </div>
                                    <a href="#" onClick={(e) => this.fnAnimationEnd(e, item)}>
                                        <ReactSVG src={close} className="tips_close_button"/>
                                    </a>
                                </div>
                                <div>
                                    <div className="title_left"/>
                                    <div className="tips_title">
                                        <span id={`title${item['@index']}`}>{item['title']}</span>
                                    </div>
                                </div>
                            </div>)
                    })
                }
            </div>
        )
    };

    fnAnimationEnd = (e, item) => {
        if(e != null) {
            e.preventDefault();
        }
        this.state.tipObjects.forEach((o, i) => {
            if(item['@index'] === o['@index']) {
                const element = document.getElementById(item['@index']);
                element.style.removeProperty("animation");
                this.state.tipObjects.splice(i, 1);
                this.setState(this.state);
            }
        })
    };

    static open = (options) => {
        options['@index'] = "_" + new Date().getTime();

        _that.state.tipObjects.push(options);
        _that.setState({
            isOpen: true,
            tipObjects: _that.state.tipObjects
        });
        const titleDOM = document.getElementById("title" + options['@index']);
        if(titleDOM !== null) {
            titleDOM.innerHTML = options['title']
        }
    };

    static tip = (options) => {
        this.open(options)
    };

    static error = (title) => {
        const options = {
            title: title,
            type: 'warning',
        };
        this.open(options);
    };

    static warning = (title) => {
        const options = {
            title: title,
            type: 'warning',
        };
        this.open(options);
    };

    static info = (title) => {
        const options = {
            title: title,
            type: 'warning',
        };
        this.open(options);
    };

    static success = (title) => {
        const options = {
            title: title,
            type: 'success',
        };
        this.open(options);
    };

    componentDidMount() {
        if(_interval === null) {
            // tip显示时长为2s,在1.5s后消失
            _interval = setInterval(function() {
                if(_that.state !== null
                    && _that.state.tipObjects != null
                    && _that.state.tipObjects.length > 0) {

                    const current = _that.state.tipObjects[0];
                    const element = document.getElementById(current['@index']);
                    element.style.animation = "action 1s";
                    element.style.animationDelay = "2s";
                }
            }, 500)
        }
    }

    componentWillUnmount() {
        clearInterval(_interval);
    }
};