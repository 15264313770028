import axios from "../../service/http";
import {isEmptyString} from "./tool";

export function loadLocalInfo(lang) {
    axios.get(`/saas/comm/local.do?lang=${lang}`).then((res) => {
        res = res.data;
        if (res != null && res["status"] === 'success') {
            const local = res['data'];
            localStorage.setItem("local", JSON.stringify(local));
            window.I18n = local;
            localStorage.setItem("lang", lang);
            window.parent.location.reload();
        }
        else {
            console.log("error", res['message']);
        }
    });
}

export function i18n(key, ...params) {
    let config = window.I18n;

    if(isEmptyString(config)) {
        config = localStorage.getItem("local");

        if(!isEmptyString(config)) {
            window.I18n = config;
        }
    }
    config = config || "{}";
    config = (typeof config === "string") ? JSON.parse(config) : config;
    let v = config[key];
    v = isEmptyString(v) ? key : v;

    if(params && params.length > 0) {
        params.forEach((param, index) => {
            v = v.replace(`{${index}}`, param);
        });
    }

    return v;
}

export default class I18N {

}

I18N.SAAS_SYS_REPORT = "saas.sys.report";
I18N.SAAS_SYS_DATA = "saas.sys.data";
I18N.SAAS_SYS_HELP = "saas.sys.help";
I18N.SAAS_SYS_NAME = "saas.sys.name";
I18N.SAAS_SYS_PERSONAL_CENTER = "saas.sys.personal.center";
I18N.SAAS_SYS_YONGHONG_CLUB = "saas.sys.yonghong.club";
I18N.SAAS_SYS_LOGOUT = "saas.sys.logout";
I18N.SAAS_SYS_ROOT_PATH = "saas.sys.root.path";
I18N.SAAS_REPORT_PUBLIC = "saas.report.public";
I18N.SAAS_REPORT_EXTRACT = "saas.report.extract";
I18N.SAAS_REPORT_SHARE = "saas.report.share";
I18N.SAAS_SYS_DELETE = "saas.sys.delete";
I18N.SAAS_SYS_CONFIRM = "saas.sys.confirm";
I18N.SAAS_SYS_DEL_CONFIRM = "saas.sys.del.confirm";
I18N.SAAS_SYS_CANCEL = "saas.sys.cancel";
I18N.SAAS_SYS_AFFIRM = "saas.sys.affirm";
I18N.SAAS_SYS_NO_MORE = "saas.sys.no.more";
I18N.SAAS_REPORT_PUBLIC_SHARE = "saas.report.public.share";
I18N.SAAS_REPORT_ENCRYPTED_SHARE = "saas.report.encrypted.share";
I18N.SAAS_REPORT_OUT_DAYS = "saas.report.out.days";
I18N.SAAS_REPORT_DAY = "saas.report.day";
I18N.SAAS_REPORT_COPY_URL = "saas.report.copy.url";
I18N.SAAS_REPORT_OPEN_URL = "saas.report.open.url";
I18N.SAAS_REPORT_QRCODE_SHARE = "saas.report.qrcode.share";
I18N.SAAS_SYS_CLOSE = "saas.sys.close";
I18N.SAAS_REPORT_SCAN_QRCODE = "saas.report.scan.qrcode";
I18N.SAAS_REPORT_INPUT_PASSWORD = "saas.report.input.password";
I18N.SAAS_REPORT_SHARE_TIPS = "saas.report.share.tips";
I18N.SAAS_REPORT_INPUT_PWD_TIPS = "saas.report.input.pwd.tips";
I18N.SAAS_REPORT_INPUT_PWD = "saas.report.input.pwd";
I18N.SAAS_REPORT_OPEN = "saas.report.open";
I18N.SAAS_SYS_ERROR = "saas.sys.error";
I18N.SAAS_REPORT_PWD_INVALID = "saas.report.pwd.invalid";
I18N.SAAS_DATA_BATCH_DELETE = "saas.data.batch.delete";
I18N.SAAS_DATA_TOTAL_ROWS = "saas.data.total.rows";
I18N.SAAS_DATA_SELECT_ALL = "saas.data.select.all";
I18N.SAAS_DATA_QUERY_NAME = "saas.data.query.name";
I18N.SAAS_DATA_QUERY_TYPE = "saas.data.query.type";
I18N.SAAS_DATA_QUERY_PATH = "saas.data.query.path";
I18N.SAAS_DATA_QUERY_ROWS = "saas.data.query.rows";
I18N.SAAS_DATA_LINKED_DASHBOARD = "saas.data.linked.dashboard";
I18N.SAAS_DATA_UPDATE_TIME = "saas.data.update.time";
I18N.SAAS_DATA_OPERATE = "saas.data.operate";
I18N.SAAS_SYS_TIPS = "saas.sys.tips";
I18N.SAAS_DATA_QUERY_DEL_TIPS = "saas.data.query.del.tips";
I18N.SAAS_SYS_PAGE_PRE = "saas.sys.page.pre";
I18N.SAAS_SYS_PAGE_PAGING = "saas.sys.page.paging";
I18N.SAAS_SYS_PAGE_NEXT = "saas.sys.page.next";
I18N.SAAS_HELP_STEP_FIRST = "saas.help.step.first";
I18N.SAAS_HELP_STEP_FIRST_TIPS = "saas.help.step.first.tips";
I18N.SAAS_HELP_STEP_SECOND = "saas.help.step.second";
I18N.SAAS_HELP_STEP_SECOND_TIPS = "saas.help.step.second.tips";
I18N.SAAS_HELP_STEP_THIRD = "saas.help.step.third";
I18N.SAAS_HELP_STEP_THIRD_TIPS = "saas.help.step.third.tips";
I18N.SAAS_HELP_PRODUCT_MORE = "saas.help.product.more";
I18N.SAAS_API_OUT_DAYS_TIPS = "saas.api.out.days.tips";
I18N.SAAS_API_DELETE_TIPS = "saas.api.delete.tips";
I18N.SAAS_API_SYS_ERROR = "saas.api.sys.error";
I18N.SAAS_REPORT_OPERATE_SHARE = "saas.report.operate.share";
I18N.SAAS_REPORT_SHARE_FAIL = "saas.report.share.fail";
I18N.SAAS_REPORT_SHARE_URL = "saas.report.share.url";
I18N.SAAS_REPORT_SHARE_COPY_SUCCESS = "saas.report.share.copy.success";
I18N.SAAS_REPORT_SHARE_PWD = "saas.report.share.pwd";
I18N.SAAS_REPORT_DELETE_MOBILE_TIPS = "saas.report.delete.mobile.tips";
I18N.SAAS_REPORT_DELETE_TIPS = "saas.report.delete.tips";
I18N.SAAS_REPORT_DELETE_FAIL = "saas.report.delete.fail";
I18N.SAAS_REPORT_QUERY_FAIL = "saas.report.query.fail";
I18N.SAAS_SYS_BACK_INDEX = "saas.sys.back.index";
I18N.SAAS_SYS_LOGIN_LOADING = "saas.sys.login.loading";
I18N.SAAS_SYS_WEB_COPYRIGHT = "saas.sys.web.copyright";
I18N.SAAS_DATA_EMPTY_TIPS = "saas.data.empty.tips";
I18N.SAAS_REPORT_EMPTY_TIPS = "saas.report.empty.tips";
I18N.SAAS_DATA_QUERY_FAIL = "saas.data.query.fail";
I18N.SAAS_DATA_DELETE_EMPTY_TIPS = "saas.data.delete.empty.tips";
I18N.SAAS_DATA_DELETE_FAIL = "saas.data.delete.fail";
I18N.SAAS_SYS_LOADING_WAITE = "saas.sys.loading.waite";
I18N.SAAS_SYS_NOT_FIND = "saas.sys.not.find";
I18N.SAAS_REPORT_PWD_PLACEHOLDER = "saas.report.pwd.placeholder";
I18N.SAAS_REPORT_DAYS_PLACEHOLDER = "saas.report.days.placeholder";
I18N.SAAS_REPORT_QRCODE_IMG_SAVE = "saas.report.qrcode.img.save";
I18N.SAAS_SYS_LOGIN_FAIL = "saas.sys.login.fail";
I18N.SAAS_SYS_LOGOUT_FAIL = "saas.sys.logout.fail";
I18N.SAAS_SYS_REQUEST_TIMEOUT = "saas.sys.request.timeout";
I18N.SAAS_SYS_LANG_SETTING = "saas.sys.lang.setting";
I18N.SAAS_SYS_QUERY = "saas.sys.query";
I18N.SAAS_SYS_CONN = "saas.sys.conn";
I18N.SAAS_DATA_CONN_NAME = "saas.data.conn.name";
I18N.SAAS_DATA_CONN_TYPE = "saas.data.conn.type";
I18N.SAAS_DATA_CONN_PATH = "saas.data.conn.path";
I18N.SAAS_DATA_LINKED_QUERY = "saas.data.linked.query";
I18N.SAAS_VIP_OPEN = "saas.vip.open";
I18N.SAAS_VIP_RENEW = "saas.vip.renew";
I18N.SAAS_VIP_NAME = "saas.vip.name";
I18N.SAAS_VIP_SUPER_NAME = "saas.vip.super.name";
I18N.SAAS_VIP_POINT = "saas.vip.point";
I18N.SAAS_VIP_EXPIRATIONDATE = "saas.vip.ExpirationDate";
I18N.SAAS_HELP_PROCESS = "saas.help.process";
I18N.SAAS_HELP_MORE = "saas.help.more";
I18N.SAAS_DOWNLOAD = "saas.download";
I18N.SAAS_OPERATION_PROCESS = "saas.operation.process";
I18N.SAAS_RELATED_RECOMMEND = "saas.related.recommend";
I18N.SAAS_DESKTOP_DESC = "saas.desktop.desc";
I18N.SAAS_MARKET_DESC = "saas.market.desc";
I18N.SAAS_REPORT_DATA_FAILURE_SHARE = "saas.report.data.failure.share";
I18N.SAAS_REPORT_DATA_FAILURE_NO_VIP = "saas.report.data.failure.no.vip";
I18N.SAAS_REPORT_SHARE_SUCCESS = "saas.report.share.success";
I18N.SAAS_DOWNLOAD_FAILURE = "saas.download.failure";
I18N.SAAS_IEBROWSER_SPAN1 = "saas.IEbrowser.span1";
I18N.SAAS_IEBROWSER_SPAN2 = "saas.IEbrowser.span2";
I18N.SAAS_IEBROWSER_SPAN4 = "saas.IEbrowser.span4";
I18N.SAAS_HELP_MARKET = "saas.help.market";
I18N.SAAS_DATA_QUERY_DEL_SOURCETIPS = "saas.data.query.del.sourcetips";
I18N.SAAS_EDU = "saas.edu";