import React, {Component} from 'react';
import {ReactSVG} from "react-svg";
import './Report.scss';
import more from "../../assets/images/saas/more.svg"
import share from "../../assets/images/saas/share.svg"
import del from "../../assets/images/saas/delete.svg"
import {fnIsMobile} from "../Util/tool";
import I18N, {i18n} from "../Util/I18nUtils";
import iconSimple from "../../assets/images/saas/pic.svg"
import iconJiXi from "../../assets/images/saas/db_jixiyun.svg"
import iconZuHe from "../../assets/images/saas/db_zuheyun.svg"
import iconDaPing from "../../assets/images/saas/db_daping.svg"
import vipTag from "../../assets/images/saas/vip_true.svg"
import vipFalseTag from "../../assets/images/saas/vip_false.svg"

export default class Report extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mobile: {
                hidden: true
            }
        };
        this.currentReportId = null;
        this.allReportId = [];
    }

    icon = {
        // 普通报告
        16640: iconSimple,
        // 大屏报告
        16643: iconDaPing,
        // 即席分析
        16642: iconJiXi,
        // 组合报告
        16644: iconZuHe,
    };

    render() {
        let {path, name, type, id, fnShare, fnDelete, updateDate, isShared, isExtract} = this.props;
        const iconStyle = {
            backgroundImage: `url(${this.icon[type] || iconSimple})`
        };
        let style1 = (isExtract && isShared) ? "extractTag" : "shareTag";

        return (
            <div id={id} className='report mobile_click'>
               <a href={fnIsMobile() ? null : `/saas/db/viewReport.do?lang=${window.localStorage.getItem("lang")}&id=${id}`} target="_blank"
                   onTouchMove={this._fnOnTouchMove}
                   onTouchEnd={(e) => this._fnOnTouchEnd(e, `/saas/db/viewReport.do?id=${id}`)}
                >
                    <div style={{position: 'relative'}}>
                        <div className='con'>
                            <div className="con-img-text">
                                <div className="left-img-div">
                                    <div className="left-img" style={iconStyle}/>
                                </div>
                                <div className="right-context">
                                    <p className='report_name' title={name}>{name}</p>
                                    <p className='report_path' title={path}>{path}</p>
                                    <p className='create_time' title={updateDate}>{updateDate}</p>
                                </div>
                            </div>
                            {isExtract ?
                                <label className={style1}>{i18n(I18N.SAAS_REPORT_EXTRACT)}</label>
                                : null}
                            {isShared ?
                                <label className="shareTag">{i18n(I18N.SAAS_REPORT_PUBLIC)}</label>
                                : null}
                            <div className='more_class' id="pc" name="pc"
                                 onClick={(e) => this.fnMoreClick(e, id)}>
                                <ReactSVG src={more} className="more_action_ico"/>
                                <div id={`more_action_${id}`} className="more_action">
                                    <a id="share" href="#" onClick={() => this._fnClick(fnShare, id)}>
                                        <div><ReactSVG src={share} wrapper="span" className="share-icon"/>{i18n(I18N.SAAS_REPORT_OPERATE_SHARE)}</div>
                                    </a>
                                    <a id="delete" href="#" onClick={() => this._fnClick(fnDelete, id)}>
                                        <div><ReactSVG src={del} wrapper="span" className="delete-icon"/>{i18n(I18N.SAAS_SYS_DELETE)}</div>
                                    </a>
                                </div>
                            </div>
                            <div className='more_class' id="mobile"
                                 onTouchEnd={(e) => this.fnMobileShow(e)}>
                                <ReactSVG src={more} className="more_action_ico more_action_ico_mobile"/>
                            </div>
                        </div>
                    </div>
                </a>
                <div hidden={this.state.mobile.hidden} className="mask">
                    <div className="mobile_dom" id="mobile_dom" hidden={this.state.mobile.hidden}>
                        <div className="title" onClick={this._fnPreventDefault}>{name}</div>
                        <hr/>
                        <div className="share" onClick={(e) => this._fnMobileShare(e, fnShare)}>
                            {i18n(I18N.SAAS_REPORT_SHARE)}
                        </div>
                        <hr/>
                        <div className="delete" onClick={(e) => this._fnMobileDelete(e, fnDelete)}>
                            {i18n(I18N.SAAS_SYS_DELETE)}
                        </div>
                        <div className="cancel" onClick={(e) => this.fnMobileHidden(e)}>
                            {i18n(I18N.SAAS_SYS_CANCEL)}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    _fnOnTouchMove = () => {
        window._isTouchMoving = true;
    };

    _fnOnTouchEnd = (e, uri) => {
        if(!window._isTouchMoving) {
            this._fnPreventDefault(e);
            e.stopPropagation();
            let reportUrl = window.location.protocol + "//" + window.location.host + uri;
            window.open(reportUrl);
        }
        window._isTouchMoving = false
    };

    _fnClick(callback, reportId) {
        const div = document.getElementById("more_action_" + reportId);
        if(div != null) {
            // 模拟全局变量 ,应该隐藏的more action
            let _closeReport = document.getElementById("_closeReport");
            if(_closeReport == null) {
                _closeReport = document.createElement("input");
                _closeReport.id = "_closeReport";
                _closeReport.hidden = true;
                document.body.appendChild(_closeReport);
            }
            _closeReport.value = "more_action_" + reportId;
        }
        if(typeof callback === "function") {
            callback();
        }
    }

    fnMoreClick(e, id) {
        this._fnPreventDefault(e);
        // 模拟全局变量
        let currentReportId = document.getElementById("currentReportId");
        if(currentReportId == null) {
            currentReportId = document.createElement("input");
            currentReportId.id = "currentReportId";
            currentReportId.hidden = true;
            document.body.appendChild(currentReportId);
        }

        let allReportId = document.getElementById("allReportId");
        if(allReportId == null) {
            allReportId = document.createElement("input");
            allReportId.id = "allReportId";
            allReportId.hidden = true;
            document.body.appendChild(allReportId);
        }

        const elementId = "more_action_" + id;
        const element = document.getElementById(elementId);

        currentReportId.value = elementId;
        if(allReportId.value.indexOf(elementId) < 0) {
            allReportId.value = allReportId.value + "," + elementId;
        }

        if(element != null) {
            // 移除动态生成的 left,bottom样式
            element.style.removeProperty("left");
            element.style.removeProperty("bottom");
            element.style.visibility = 'visible';

            // 优化 最右侧的 菜单被遮挡的bug ====
            // 计算x坐标
            let actualLeft = element.offsetLeft;
            let actualTop = element.offsetTop;
            let current = element.offsetParent;
            while(current !== null) {
                actualLeft += current.offsetLeft;
                actualTop += current.offsetTop;
                current = current.offsetParent;
            }
            // 如果右侧超出可视区域，则更改其坐标
            const pointX = actualLeft + element.offsetWidth;
            const browserWidth = window.innerWidth;
            if(pointX > browserWidth) {
                const width = pointX - browserWidth;
                element.style.left = -(width + 20) + 'px';
            }

            // 如果底部超出可视区域，则更改其坐标
            const pointY = actualTop + element.offsetHeight;
            const browserHeight = window.innerHeight - 80 + document.getElementById("_scroll").scrollTop;
            if(pointY > browserHeight) {
                element.style.bottom = 10 + 'px';
            }
            // 优化 最右侧的 菜单被遮挡的bug ====
        }
    }

    _fnPreventDefault(e) {
        if(e != null) {
            try {
                e.preventDefault();
            }
            catch(e) {
                console.log('error', e)
            }
        }
    }

    _fnMobileShare = (e, callback) => {
        callback(e);
        this.setState({mobile: {hidden: true}})

    };

    _fnMobileDelete = (e, callback) => {
        callback(e);
        this.setState({mobile: {hidden: true}})
    };

    fnMobileShow = (e) => {
        this._fnPreventDefault(e);
        e.stopPropagation();
        this.setState({mobile: {hidden: false}})
    };

    fnMobileHidden = (e) => {
        this._fnPreventDefault(e);
        this.setState({mobile: {hidden: true}})
    }
}
