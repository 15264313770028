import React, {Component} from 'react';
import {NavLink, Link} from 'react-router-dom';
import {isLogin, getUsername, logout, getLoginUrl} from '../../service/Auth';
import Background from '../../assets/images/logo_new_yun.svg';
import jp_Background from '../../assets/images/saas/vividata/logo_jp_new.svg';
import en_Background from '../../assets/images/saas/vividata/vividime-01.svg';
import './NavigateBar.scss';
import icon_reports from '../../assets/images/saas/icon_reports.svg'
import icon_data from '../../assets/images/saas/icon_data.svg'
import icon_help from '../../assets/images/saas/icon_help.svg'
import {ReactSVG} from "react-svg";
import club from "../../assets/images/saas/club.svg"
import signUp from "../../assets/images/saas/signUp.svg"
import lang from "../../assets/images/saas/lang.svg"
import icon_right from "../../assets/images/saas/icon_right.svg"
import icon_checked from "../../assets/images/saas/icon_checked.svg"
import center from "../../assets/images/saas/center.svg"
import exit from "../../assets/images/saas/exit.svg"
import down_arrow from "../../assets/images/saas/down_arrow.svg"
import ReactTooltip from 'react-tooltip';
import I18N, {i18n, loadLocalInfo} from "../Util/I18nUtils";
import {userLanguage} from "../Util/tool";
import user_vip from "../../assets/images/saas/user_vip.svg";
import user_notVip from "../../assets/images/saas/user_notVip.svg";
import axios from '../../service/http';
import Tips from "../Tips/Tips";
import RollingTip from "../RollingTip/RollingTip";
import Cookie from "react-cookies";

class NavigateBar extends Component {

    constructor(props) {
        super(props);
    }
    _getUsername = async() => {
        const username = await getUsername(false);
        this.setState({
            username: isLogin() && username
        })
    };

    _initActivityInfo = () => {
        axios.get('/saas/activity/data.do', {})
            .then(resp => {
                const data = resp.data || {};
                if(data['status'] === 'success') {
                    console.log(data['data']);
                    this.initActivityInfo(data['data'])
                }
            })
            .catch(error => {
                console.error(error);
                Tips.error(i18n(I18N.SAAS_SYS_ERROR))
            })
    };

    initActivityInfo = (data) => {
        this.setState({
            tipMessage: data['tipMessage'],
            activities: data['activities'],
            userInfo:data['user'],
            isLoad: true,
        })
    };

    isVip = () => {
        const time = this.state.userInfo?.vipExpireTime;
        return time && new Date(time) > new Date();
    };

    getVipDate = () => {
        return this.state.userInfo?.vipExpireTime?.substr(0,10);
    }

    state = {
        username: null,
        isLoad: false,
        loginUrl: localStorage.getItem("loginUrl"),
        lang: {
            "zh_CN": "中文",
            "ja_JP": "日本",
            "en_US": "英文"
        },
        lang_used:"zh_CN",
        logo: {
            "zh_CN": Background,
            "ja_JP": jp_Background,
            "en_US": en_Background
        },
        homeUrl: {
            "zh_CN": 'https://club.yonghongtech.com/home.php?mod=spacecp&ac=plugin&id=hl_rename:rename',
            "en_US": 'http://club.vividime.com/home.php?mod=spacecp&ac=avatar'
        },
        clubUrl: {
            "zh_CN": 'https://club.yonghongtech.com/forum-44-1.html',
            "en_US": 'http://club.vividime.com/index.php',
        }
    };

    render() {
        const backgroundStyle = {
            background: `url(${this.state.logo[this.state.lang_used]})`,
            textIndent: '-9999px',
        };

        const loginBoxStyle = {
            border: 'none',
            fontSize: '14px',
            color: '#9b9b9b',
        };

        const userStyle = {
            display: 'inline-block',
            overflow: 'hidden',
        };

        let navBarItemList = [
            {
                id: 0,
                key: 'dashboard',
                value: i18n(I18N.SAAS_SYS_REPORT),
                icon: icon_reports
            },
            {
                id: 1,
                key: 'data',
                value: i18n(I18N.SAAS_SYS_DATA),
                icon: icon_data
            },
            {
                id: 2,
                key: 'help',
                value: i18n(I18N.SAAS_SYS_HELP),
                icon: icon_help
            },
        ];
        let {username, isLoad, tipMessage} = this.state;
        let {page, needRouter} = this.props;

        return (
            <div className='head'>
                <ReactTooltip
                    type="light"
                    delayShow={500}
                    border={true}
                    backgroundColor='white'
                    multiline={true}
                    className="react-tip-class"
                    place='bottom'
                    getContent={this._fnGetContent}
                />
                <div className='Tem_Container'>
                    <div className='hd_content'>
                        <Link to={`/${navBarItemList[0].key}`}>
                            <div className='fm_logo' style={backgroundStyle}/>
                        </Link>
                        {needRouter ? (
                            <div className='fm_nav'>
                                <ul>
                                    {navBarItemList.map((item) => (
                                        <NavLink
                                            to={`/${item.key}`}
                                            activeClassName='current'
                                            key={item.id}
                                            className={
                                                (item.id === 1 && page === 'HelpCenter') || (item.id === 2 && page === 'DataManage')
                                                    ? 'current'
                                                    : ''
                                            }>
                                            <li>
                                                <ReactSVG
                                                    src={item.icon}
                                                    className="icon_style"
                                                />
                                                {item.value}
                                            </li>
                                        </NavLink>
                                    ))}
                                </ul>
                            </div>
                        ) : null}
                        <div>
                            {isLogin() ? (
                                <div className='fm_userinfo' style={loginBoxStyle}>
                                    {this.state.activities && this.state.activities.length > 0 ?
                                        <div className={"activities-rolling"} style={{display:this.$config.rolling_hidden}}>
                                            <RollingTip
                                                msgList={this.state.activities}
                                            />
                                        </div> : null
                                    }
                                    {isLoad ? <div className={"open-vip-tip"}>
                                        <button className={"open-vip-tip-btn"} onClick={this._toVip}
                                                style={{"--tip-msg": "99999"}}>
                                            {`${(this.isVip() ? i18n(I18N.SAAS_VIP_RENEW) : i18n(I18N.SAAS_VIP_OPEN))}`}
                                            {i18n(I18N.SAAS_VIP_NAME)}
                                            {tipMessage === "" ? null : <span style={{display:this.$config.en_hidden}}>{tipMessage}</span>}
                                        </button>
                                    </div> : null
                                    }
                                    <div id='username' className={"user-name"} data-tip={`${username}`}>
                                        <div style={userStyle}>
                                            <img alt={""} src={this.isVip() ? user_vip : user_notVip} className={"vip-icon"}/>
                                            <div id="span_name" className="user_name">{username}</div>
                                        </div>
                                        <ReactSVG src={down_arrow}
                                                  className='icon_downArrow'/>

                                        <div className="user_operate">
                                            <div className={"vip-info"}>
                                                <div>
                                                    <button onClick={this._toVip} className={"info-open-vip"}>
                                                        {`${(this.isVip() ? i18n(I18N.SAAS_VIP_RENEW) : i18n(I18N.SAAS_VIP_OPEN))}`}
                                                        {i18n(I18N.SAAS_VIP_SUPER_NAME)}
                                                    </button>
                                                </div>
                                                <div>
                                                    {i18n(I18N.SAAS_VIP_POINT)}:{this.state.userInfo?.clubPoints}
                                                </div>
                                                {this.isVip() ? <div>
                                                    {i18n(I18N.SAAS_VIP_EXPIRATIONDATE)}：{this.getVipDate()}
                                                </div> : null}
                                                <div style={{height: "16px"}}></div>
                                            </div>
                                            <a target='_blank'
                                               href={this.state.homeUrl[this.state.lang_used]}>
                                                <div id='home' className="a_dom">
                                                    <ReactSVG
                                                        src={center}
                                                        className="svg_css"/>
                                                    <span className="svg_css_text">
                                                {i18n(I18N.SAAS_SYS_PERSONAL_CENTER)}
                                            </span>
                                                </div>
                                            </a>
                                            <a target='_blank' href={this.state.clubUrl[this.state.lang_used]}>
                                                <div id='club' className="a_dom">
                                                    <ReactSVG
                                                        src={club}
                                                        className="svg_css"/>
                                                    <span className="svg_css_text">
                                                {i18n(I18N.SAAS_SYS_YONGHONG_CLUB)}
                                            </span>
                                                </div>
                                            </a>

                                            <a style={{display:this.$config.edu_hidden}} target='_blank'>
                                                <Link target='_blank' to={`/signUp`}>
                                                    <div id='signUp' className="a_dom">
                                                        <ReactSVG
                                                            src={signUp}
                                                            className="svg_css"/>
                                                        <span className="svg_css_text">
                                                    {i18n(I18N.SAAS_EDU)}
                                                </span>
                                                    </div>
                                                </Link>
                                            </a>
                                            
                                            <a id="a_lang">
                                                <div id='lang' className="a_dom">
                                                    <ReactSVG
                                                        src={lang}
                                                        className="svg_css"/>
                                                    <span className="svg_css_text">{i18n(I18N.SAAS_SYS_LANG_SETTING) || '语言设置'}</span>
                                                    <ReactSVG
                                                        src={icon_right}
                                                        className="svg_css lang_icon"/>
                                                    <div className="lang-split"/>
                                                    <div className="lang-options">
                                                        <ol>
                                                            <li onClick={() => this._fnLanguageChange("zh_CN")}>
                                                                <div><span>简体中文</span>
                                                                {this._fnRenderSelected("zh_CN")}
                                                                </div>
                                                            </li>
                                                            <li onClick={() => this._fnLanguageChange("en_US")}>
                                                                <div>
                                                                    <span>English</span>
                                                                    {this._fnRenderSelected("en_US")}
                                                                </div>
                                                            </li>
                                                        </ol>
                                                    </div>
                                                </div>
                                            </a>
                                            <a>
                                                <div id='logout' onClick={logout} className="a_dom">
                                                    <ReactSVG
                                                        src={exit}
                                                        className="svg_css"/>
                                                    <span className="svg_css_text">
                                            {i18n(I18N.SAAS_SYS_LOGOUT)}
                                        </span>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className='fm_userinfo' style={loginBoxStyle}>
                                    <a href={this.state.loginUrl}>登录/注册</a>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    /**
     * 只有出现省略号才显示tip....
     * @param text
     * @returns {null|DataTable._fnGetContent.props|string}
     * @private
     */
    _fnGetContent(text) {
        const event = window.event;
        if(event != null) {
            const dom = event.target;
            if(dom != null) {
                if(dom.scrollWidth > dom.offsetWidth) {
                    return text;
                }
                return null;
            }
        }
        return text;

    }

    _toVip(){
        const buyVIP = window.publicConfig.buy_VIP_url;
        let uid = Cookie.load('user_id');
        let code = Cookie.load('user_code')
        let lang =   localStorage.getItem("lang");
        console.log(lang);
        //验证
        axios.get(`/saas/checkUser.do`).then(res => {
            res = res ? res.data : {};

            if(res != null && res["status"] === 'success') {
                let reportUrl = buyVIP+"?userId=" + uid + "&code=" + code + "&source=saas";
                reportUrl = lang === "en_US" ? reportUrl + "&locale=" + lang+ "&payEnable=false" +
                    "&internationalEdition=true" : reportUrl;
                window.open(reportUrl);
            }
            else {
                Cookie.remove('token');
                window.location.reload();
            }
        })

    }

    _fnLanguageChange(lang){
        loadLocalInfo(lang);
        this.setState({
            lang_used: lang
        });
    }

    _fnRenderSelected(lang){
        const selected = this.state.lang_used === lang;

        if(!selected){
            return null;
        }

        return(<>
            <ReactSVG
                src={icon_checked}
                className="svg_css lang_checked"/>
        </>)
    }

    _fnLoadSetting() {
        axios.get(`/saas/comm/url/setting.do`).then(res => {
            res = res ? res.data : {};

            if(res != null && res["status"] === 'success') {
                this.setState({
                    setting: {
                        homeUrl: res.data['homeUrl'],
                        clubUrl: res.data['clubUrl']
                    }
                })
            }
        })
    }

    componentWillMount() {
        const lang = userLanguage();
        this.setState({
            lang_used: lang
        });

        this._fnLoadSetting();
        this._getUsername().then();
        this._initActivityInfo();
    }
}

NavigateBar.defaultProps = {
    needRouter: true,
};

export default NavigateBar;
