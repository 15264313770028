export function deBounce(func, wait) {
  let timer = null;
  return function (...args) {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      func(...args);
    }, wait);
  };
}

export function throttle(func, wait) {
  let pre = Date.now();
  return function () {
    let context = this;
    let args = arguments;
    let now = Date.now();
    if (now - pre >= wait) {
      func.apply(context, args);
      pre = Date.now();
    }
  };
}

export function isObjectValueEqual(a, b) {
  let aProps = Object.getOwnPropertyNames(a);
  let bProps = Object.getOwnPropertyNames(b);

  if (aProps.length !== bProps.length) {
    return false;
  }

  for (var i = 0; i < aProps.length; i++) {
    var propName = aProps[i];
    if (a[propName] !== b[propName]) {
      return false;
    }
  }
  return true;
}

export function fnIsMobile() {
  let isMobile = false;
  if(/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
    // 当前设备是移动设备
    isMobile = true;
  }
  return isMobile;
}

export function fnIsUrl(url) {
  return /^http[s]?:\/\/([\w-]+\.)+[\w-]+(\/[\w-./?%&=]*)?$/g.test(url)
}

export function fnIsIE(){
  const userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
  const isIE = userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1; //判断是否IE<11浏览器
  const isEdge = userAgent.indexOf("Edge") > -1 && !isIE; //判断是否IE的Edge浏览器
  const isIE11 = userAgent.indexOf('Trident') > -1 && userAgent.indexOf("rv:11.0") > -1;
  if(isIE) {
    const reIE = new RegExp("MSIE (\\d+\\.\\d+);");
    reIE.test(userAgent);
    const fIEVersion = parseFloat(RegExp["$1"]);
    if(fIEVersion == 7) {
      return 7;
    }
    else if(fIEVersion == 8) {
      return 8;
    }
    else if(fIEVersion == 9) {
      return 9;
    }
    else if(fIEVersion == 10) {
      return 10;
    }
    else {
      return 6;//IE版本<=7
    }
  }
  else if(isEdge) {
    return 'edge';//edge
  }
  else if(isIE11) {
    return 11; //IE11
  }
  else {
    return -1;//不是ie浏览器
  }
}

export function isEmptyString(str) {
  return str == null || str === '' || str === 'null' || str === 'undefined' || (typeof str === 'string' && str.replace(/ /g, "") === '') || false;
}

export function userLanguage() {
  return localStorage.getItem("lang") || "zh_CN";
}