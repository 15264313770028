import React, { Component } from 'react';
import axios from '../../service/http';
import './SignUp.scss';
import {getUsername, isLogin} from '../../service/Auth';
import Tips from "../../components/Tips/Tips";
import I18N, {i18n} from "../../components/Util/I18nUtils";
import {userLanguage} from "../../components/Util/tool";
import Cookie from "react-cookies";
import {ConfigProvider, DatePicker, Space} from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import moment from 'moment'
import 'moment/locale/zh-cn'
moment.locale('zh-cn')

export default class SignUp extends Component {
   state = {
      realname: '',
      email: '',
      seccode: '',
      university: '',
      time: '',
      major: '',
      position: '',
      errInfo: '',
      success: '',
      count: 60,
      seccodeSended: false,
      firstTimeSend: true,
      lang_used:'',
      username:'',
      authed: false
   };

   _getUsername = async() => {
      const username = await getUsername(true);

      this.setState({
         username: username
      })

      if(username) {
         this.isAuthed();
      }
   };

   render() {
      let {
         realname,
         email,
         seccode,
         university,
         major,
         time,
         errInfo,
         count,
         seccodeSended,
         firstTimeSend,
         success,
         authed,
      } = this.state;
      let seccodeInputStyle = seccodeSended ? { width: 252 } : firstTimeSend ? { width: 266 } : { width: 238 };
      return (
          <div className='signContent'>
          <div className='imgBox'>
          </div>
             <div className='titleBox'>
                <span className='title1'>教育身份认证</span>
                <span className='title2'>认证成功即可获得12个月超级会员</span>
             </div>
          <div className='headerBox'>
             <div className='guideBox'>
                <div className='introduce'>
                  <span>认证介绍</span>
                </div>
                <div className='description'>
                  <span>大学生/教职工身份认证，仅支持通过教育网edu邮箱认证。如果您没有edu邮箱，请先联系学校申请，请正确如实填写认证信息，如发现乱填、盗用等，将拉黑并取消活动奖励!</span>
                </div>
                <div className='activity'>
                   <span><strong>限时活动：认证成功即可获得12个月desktop超级会员</strong></span>
                </div>
             </div>
          </div>
          <div className='formBox'>
         <form style={{height: "500px"}}>
            <div className='signTitle'>
               <div className='icon'>
                  <span>认证信息</span>
               </div>
            </div>
            <div className='loginInputBox'>
               <label>真实姓名：</label>
               {/*<font className='mark-font'>*</font>*/}
               <input
                  disabled= {authed}
                  type='text'
                  placeholder='请输入真实姓名'
                  value={realname}
                  maxLength={100}
                  onChange={(e) => this.handleOnchange(e, 'realname', "真实姓名")}
               />
            </div>
            <div className='loginInputBox'>
               <label>就读学校：</label>
               {/*<font className='mark-font'>*</font>*/}
               <input
                   disabled= {authed}
                   type='text'
                   placeholder='请输入就读学校全称'
                   value={university}
                   maxLength={100}
                   onChange={(e) => this.handleOnchange(e, 'university', "就读学校")}
               />
            </div>
            <div className='loginInputBox'>
               <label>就读专业：</label>
               {/*<font className='mark-font'>*</font>*/}
               <input
                   disabled= {authed}
                   type='text'
                   placeholder='请输入就读专业全称'
                   value={major}
                   maxLength={100}
                   onChange={(e) => this.handleOnchange(e, 'major', "就读专业")}
               />
            </div>
            <div style={{marginBottom:15,height:40}}>
               <label className={"graTime"}>入学时间：</label>
               {/*<font className='mark-font'>*</font>*/}
               {/*<input*/}
               {/*    disabled= {authed}*/}
               {/*    type='text'*/}
               {/*    max={this.getMaxTime()}*/}
               {/*    placeholder='请选择入学年月'*/}
               {/*    value={time}*/}
               {/*    id='intake'*/}
               {/*    maxLength={100}*/}
               {/*    onFocus= {(e) => this.changeType(e, 'time', "入学时间")}*/}
               {/*    onBlur={(e) => this.removeEvent(e, 'time', "入学时间")}*/}
               {/*    onChange={(e) => this.handleOnchange(e, 'time', "入学时间")}*/}
               {/*/>*/}
               {/*<Space direction="vertical">*/}
               {/*<DatePicker onChange={(e) => this.handleOnchange(e, 'time', "入学时间")} picker="month" />*/}
               {/*</Space>*/}
               <ConfigProvider locale={zhCN}>
                  <DatePicker className='dateExclude'
                      style={{ width: '300px' ,paddingLeft:7,height:'32px',borderRadius:0,marginLeft:10}}
                      picker="month"
                      placeholder="请选择日期"
                      onChange={(e) => this.handleDateOnchange(e, 'time', "入学时间")}
                  />
               </ConfigProvider>
            </div>
            <div className='seccodeBox'>
               <div className='seccodeDom'>
                  <label>教育邮箱：</label>
                  {/*<font className='mark-font'>*</font>*/}
                  <input
                      disabled= {authed}
                      type='text'
                      placeholder='请输入教育邮箱'
                      value={email}
                      onChange={(e) => this.handleOnchange(e, 'email', "教育邮箱")}
                  />
                  <div className='sendLabel' style={authed ? { display: 'none' } : null}>
                     <label onClick={this.getSeccode} className={seccodeSended ? 'seccodeSended' : 'seccodeUnSend'}>
                        {seccodeSended ? `重新发送 ${count}s` : firstTimeSend ? '发送验证码' : '重新发送验证码'}
                     </label>
                  </div>
               </div>
            </div>
            <div className='codeBox' style={authed ? { display: 'none' } : { display: 'block' }}>
               <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;验证码: &nbsp;</label>
               {/*<font className='mark-font'>*</font>*/}
               <input
                   disabled= {!seccodeSended}
                   type='text'
                   placeholder={'请输入邮箱验证码'}
                   maxLength={6}
                   value={seccode}
                   style={seccodeInputStyle}
                   onChange={(e) => this.handleOnchange(e, 'seccode', "验证码")}
               />
            </div>
            <div className='skipBox' style={errInfo ? { display: 'block' } : { display: 'none' }}>
               {errInfo ? (
                   <div className='errInfo' title={errInfo}>
                      {errInfo}
                   </div>
               ) : null}
            </div>
            <div className={this.isFormComplete() ? 'submitGreenBox' : 'submitGrayBox'} style={success || authed  ? { display: 'none' } : { display: '' }}>
               <div className='commitBtn' onClick={this.handleSubmit}>
                  <span className='commitBtn-label'>认证</span>
               </div>
            </div>
            <div className = 'authBox'  style={success || authed ? { display: 'block' } : { display: 'none' }}>
               {success || authed ? (
                   <div className='authInfo'>
                      <span className='title'>您已经完成教育认证。</span>
                      <span className='guide'>超级会员奖励已到账，请登录desktop查收。</span>
                   </div>
               ) : null}
            </div>
         </form>
          </div>
          </div>
      );
   }
   componentDidMount() {
      document.body.addEventListener('keyup', this.onEnterSubmit);

      // document.body.addEventListener('mousedown', this.clearErrorInfo);
   }

   componentWillUnmount() {
      document.body.removeEventListener('keyup', this.onEnterSubmit);
      // document.body.removeEventListener('mousedown', this.clearErrorInfo);
   }

   onEnterSubmit = (e) => {
      if (window.event) {
         e = window.event;
      }
      let code = e.charCode || e.keyCode;
      if (code === 13) {
         this.handleSubmit();
      }
   };

   clearErrorInfo = () => {
      if (this.state.errInfo) {
         this.setState({ errInfo: '' });
      }
   };

   isFormComplete = () => {
      let { realname, email, seccode, university, major, time} = this.state;

      if (realname && university && major && time && seccode && email) {
         return true;
      }
      else {
         return false;
      }
   };

   getMaxTime = () => {
      var  date_now = new Date();
      var year = date_now.getFullYear();
      var month = (date_now.getMonth() + 1) < 10 ? "0" + (date_now.getMonth() + 1) : (date_now.getMonth() + 1);
      var date = date_now.getDate() < 10 ? "0" + (date_now.getDate()) : (date_now.getDate());
      // window.document.getElementById("intake");
      // document.getElementById("intake").max = year + "-" + month + "-" + date;
      return year + "-" + month ;
   }

   changeType = (e, name, textName) => {
      e.target.type = "month";
   };

   removeEvent = (e, name, textName) => {
      if("time" === name) {
         if(!e.target.value) {
            e.target.placeholder = "请选择入学年月";
            e.target.type = "text";
         }
         else {
            e.target.type = "month";
            e.target.placeholder = "";
         }
      }
   };

   handleDateOnchange = (e, name, textName) => {
      this.setState({ [name]: e.toDate().getTime()});
   };

   handleOnchange = (e, name, textName) => {
      let regExp = "^[a-zA-Z\u4e00-\u9fa5]+$";
      let ex = new RegExp(regExp);

      if("realname" === name || "university" === name || "major" === name) {
         if(e.target.value.search(ex) != -1) {
            this.setState({ [name]: e.target.value, errInfo: '' });
         }
         else {
            this.setState({ [name]: e.target.value, errInfo: textName + '只能输入中文或英文' });
         }
      }
      else {
         this.setState({ [name]: e.target.value});
      }
   };

   isAuthed = async () => {
      let params = {
         uid: this.state.username
      };

      await axios.post('/saas/comm/isAuthed.do', {}, {params})
          .then(data => {
             if(data.data['status'] === 'success') {
                // console.log(data.data.data['uid'])
                this.setState({
                   realname: data.data.data['realname'],
                   email: data.data.data['email'],
                   university: data.data.data['university'],
                   major: data.data.data['major'],
                   time: data.data.data['time'],
                   authed: true});
             }
             else {

             }
          }).catch(e => {
             Tips.error(e.message)
          })
   }

   handleSubmit = () => {
      localStorage.clear();
      let { realname, email, seccode, university, major, time , username} = this.state;
      let { token } = this.props;
      // let { redirect } = parseUrl(window.location.href);
      // redirect = redirect || '/home';

      let params = {
         email: email ,
         realName: realname,
         code: seccode,
         university: university,
         major: major,
         time: time};

      if (this.isFormComplete() && this.checkInfo()) {
         axios.post('/saas/comm/authenCode.do', {}, {params})
             .then(res => {
                const data = res['data'];
                console.log(data);
                if(data['status'] === 'success') {
                   this.setState({ success: true });
                }
                else {
                   this.setState({ errInfo: res.data.message });
                }
             });
      }
      else {
         return;
      }
   };

   checkInfo = () => {
      let {realname, email, seccode, university, major} = this.state;
      let emailReg = /^([a-zA-Z0-9]+[_|_|\-|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,6}$/;

      if (!email) {
         this.setState({ errInfo: '请输入邮箱地址' });
         return false;
      }

      if (!emailReg.test(email)) {
         this.setState({ errInfo: '邮箱格式不正确' });
         return false;
      }

      if(email.split("@")[1].indexOf(".edu") < 0) {
         this.setState({ errInfo: '请输入教育邮箱地址' });
         return false;
      }

      let regExp = "^[a-zA-Z\u4e00-\u9fa5]+$";
      let ex = new RegExp(regExp);

      if(realname.search(ex) === -1 || university.search(ex) === -1 || major.search(ex) === -1 ) {
         this.setState({ errInfo: '真实姓名、大学、专业只能输入中文或英文' });
         return false;
      }

      this.setState({ errInfo: '' });
      return true;
   };

   getSeccode = () => {
      let { email, seccodeSended } = this.state;
      let params = {email: email };

      if (!seccodeSended && this.checkInfo()) {
         axios.post('/saas/comm/sendEmail.do', {}, {params})
             .then(res => {
                const data = res['data'];
                if(data['status'] === 'success') {
                   this.setState({ seccodeSended: true});
                   this.seccodeCountDown();
                }
                else {
                   this.setState({ errInfo: res.data.message});
                }
             })
             .catch((err) => {
                this.setState({ errInfo: "发送邮箱失败，请确定邮箱"});
         });
      } 
      else {
         return;
      }
   };

   seccodeCountDown = () => {
      let { count } = this.state;
      
      if (count === 1) {
         this.setState({ count: 60, seccodeSended: false, firstTimeSend: false });
      } 
      else {
         this.setState({ count: count - 1, seccodeSended: true });
         setTimeout(this.seccodeCountDown, 1000);
      }
   };

   componentWillMount() {
      const lang = userLanguage();
      this.setState({
         lang_used: lang
      });

      this._getUsername().then();
   }
}
